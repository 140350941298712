import UrlTemplate from 'url-template';
import { parse, getTime, format } from 'date-fns';

import * as types from './types';
import { API_URL, XML_URL, UPLOAD_URL } from './helpers';

const urls = {
	list: `${API_URL}{?params*}`,
	create: XML_URL,
	upload: `${UPLOAD_URL}{?params*}`
};

const setError = (state) => {
	state.error = true;
};

const setState = (prop) => (state, { items }) => {
	state[prop] = items;
};

const getFormFetch = (body) => window.fetch(urls.create, {
	headers: { 'content-type': 'application/x-www-form-urlencoded' },
	body,
	method: 'POST',
	mode: 'cors',
	credentials: 'include'
});

export default {
	namespaced: true,
	state(){
		return {
			list: [],
			item: {},
			listLength: 0,
			provider: [],
			customers: [],
			storegroups: [],
			materials: [],
			stores: [],
			error: false,
		};
	},
	getters: {
		getJobs: (state) => {
			return state.list.map(item => {
				const deadline = parse(item.deadline, 'dd.MM.yy', new Date());
				return {
					...item,
					deadline_timestamp: getTime(deadline),
				};
			});
		},
		getItem: (state) => state.item,
		getProvider: (state) => state.provider.map((provider) => ({
			...provider,
			bezeichnung: provider.name,
		})),
		getCustomers: (state) => state.customers,
		getStoreGroups: (state) => state.storegroups,
		getMaterials: (state) => state.materials,
		getStores: (state) => state.stores.map((store) => ({
			...store,
			bezeichnung: `${ store.name }, ${ store.strasse }, ${ store.plz } ${ store.ort }`,
		})),
		getListLength: (state) => state.listLength,
	},
	actions: {
		getList({ commit }, { id, start, end, term = '' }) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'dekorateurauftraege',
				term,
				sortby: 'auftraege.id',
				sortdir: 'DESC',
			};
			const range = start !== undefined && end !== undefined ? { 'Range': `items=${start}-${end}` } : {};
			const url = UrlTemplate.parse(urls.list).expand({ params });

			return window.fetch(url, { 
				headers: { ...range	}
			})
				.then((response) => {
					if(range['Range']) {
						commit(types.JOBS_LIST_SUCCESS_LENGTH, parseInt(response.headers.get('Content-Range').split('/').pop(), 10));
					}
					return response.json();
				})
				.then((result) => {
					if(range['Range']) {
						commit(types.JOBS_LIST_SUCCESS, result);	
						return Promise.resolve();
					}

					commit(types.JOBS_LIST_SUCCESS, result.items);
					commit(types.JOBS_LIST_SUCCESS_LENGTH, result.items.length);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));
		},
		resetList({ commit }) {
			commit(types.JOBS_LIST_RESET);
		},
		getItem({ commit }, { id, itemId }) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'dekorateurauftrag_view',
				id: itemId,
			};
			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then((result) => {
					commit(types.JOB_ITEM_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));
		},
		getProvider({ commit }, id) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'provider',
			};

			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then(result => {
					commit(types.PROVIDER_LIST_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));
		},
		getCustomers({ commit }, id) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'kunden',
			};

			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then(result => {
					commit(types.CUSTOMER_LIST_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));			
		},
		getStoreGroups({ commit }, id) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'filialgruppen',
			};

			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then(result => {
					commit(types.STOREGROUP_LIST_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));			
		},
		getMateriels({ commit }, { id, customerId }) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'materialien',
				id: customerId,
			};

			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then(result => {
					commit(types.MATERIALS_LIST_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));			
		},
		getStores({ commit }, { id, storeGroupId }) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				'PHPSESSID': id,
				action: 'filialen',
				id: storeGroupId,
				sortby: 'name'
			};

			const url = UrlTemplate.parse(urls.list).expand({ params });
			return window.fetch(url)
				.then((response) => response.json())
				.then(result => {
					commit(types.STORE_LIST_SUCCESS, result);
					return Promise.resolve();
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));			
		},		
		createJob(_, { userId, customerId, storeGroupId, materialId, storeId, date }) {
			const formattedDate = format(new Date(date), 'dd.MM.yyyy');
			const body = new URLSearchParams(`xajax=dekorateurauftrag_freicreate&xajaxr=${new Date().getTime()}&xajaxargs[]=<xjxobj><e><k>user_id</k><v>${userId}</v></e><e><k>materialgruppe_id</k><v>${customerId}</v></e><e><k>filialgruppe_id</k><v>${storeGroupId}</v></e><e><k>material_id</k><v>${materialId}</v></e><e><k>filiale_id</k><v>${storeId}</v></e><e><k>fertigstellung</k><v>${formattedDate}</v></e></xjxobj>`);

			return getFormFetch(body)
				.then(response => response.text())
				.then(xml => {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const id  = xmlDoc.getElementsByTagName('cmd')[0].childNodes[0].nodeValue;
					if(!id) {
						return Promise.resolve(null);
					}
					return Promise.resolve(id);
				});
		},
		uploadImage({ commit }, { id, jobId, files }) {
			commit(types.IS_LOADING, null, { root: true });
			const params = {
				params: id,
				id: jobId,
			};
			const body = new FormData();
			files.forEach(file => {
				body.append('Filedata[]', file);
			});

			return window.fetch(UrlTemplate.parse(urls.upload).expand({ params }), {
				body,
				method: 'POST',
				mode: 'cors',
				credentials: 'include',
			})
				.then(response => {
					if(response.status === 200) {
						return Promise.resolve(true);
					}
					return Promise.resolve(false);
				})
				.catch(() => commit(types.LIST_FAILURE))
				.finally(() => commit(types.IS_NOT_LOADING, null, { root: true }));
		},
		addImages({ commit }, files) {
			commit(types.ADD_IMAGES, files);
			return Promise.resolve();
		},
		deleteImage({ commit }, file) {
			if(file.startsWith('blob')) {
				commit(types.DELETE_IMAGE, file);
				return;
			}
			const id = file.split('.').shift();
			const body = new URLSearchParams(`xajax=dekorateurauftrag_deletefoto&xajaxr=${new Date().getTime()}&xajaxargs[]=${id}`);
			return getFormFetch(body)
				.then(response => response.text())
				.then(() => {
					commit(types.DELETE_IMAGE, file);
					return Promise.resolve();
				});	

		},
		editJob({commit}, { 
			id,
			completionDate,
			date,
			note,
			material,
		}) {

			const body = new URLSearchParams(`xajax=dekorateurauftrag_edit&xajaxr=${new Date().getTime()}&xajaxargs[]=${id}&xajaxargs[]=<xjxobj>${completionDate ? `<e><k>dekorateur_fertigstellung</k><v>${completionDate}</v></e>` : ''}${ note ? `<e><k>dekorateur_anmerkungen</k><v>${note}</v></e>` : ''}<e><k>vereinbarter_termin</k><v>${date || ''}</v></e><e><k>material_erhalten</k><v><xjxobj>${ material ? '<e><k>1</k><v>Y</v></e>' : '<e><k>0</k><v>Y</v></e>'}</xjxobj></v></e></xjxobj>`);
			return getFormFetch(body)
				.then(response => response.text())
				.then(() => {
					commit(types.JOBS_EDIT_SUCCESS, {
						id,
						completionDate,
						date,
						note,
						material
					});
					return Promise.resolve();
				});
		},
		deleteJob({ commit }, id) {
			const body = new URLSearchParams(`xajax=auftrag_delete&xajaxr=${new Date().getTime()}&xajaxargs[]=${id}`);
			return getFormFetch(body)
				.then(response => response.text())
				.then(() => {
					commit(types.DELETE_JOB, id);
					return Promise.resolve();
				});	
		}
	},
	mutations: {
		[types.LIST_FAILURE]: setError,
		[types.JOBS_LIST_SUCCESS](state, list) {
			const newItems = list.filter(({ id }) => !state.list.some((item) => item.id === id));

			state.list = [
				...state.list,
				...newItems,
			];
		},
		[types.JOBS_LIST_RESET](state) {
			state.list = [];
		},
		[types.JOBS_LIST_SUCCESS_LENGTH](state, length) {
			state.listLength = length;
		},
		[types.JOBS_EDIT_SUCCESS](state, {
			id,
			completionDate,
			date,
			material
		}) {
			const item = state.list.find(({id: listID }) => listID === id);
			if(date) item.vereinbarter_termin = date;
			if(completionDate) item.fertig = completionDate;
			if(material !== undefined) item.material_erhalten = material;
		},
		[types.JOB_ITEM_SUCCESS](state, item) {
			state.item = item;
		},
		[types.ADD_IMAGES](state, files) {

			if(!state.item.images) {
				state.item.images = files.map(file => URL.createObjectURL(file));
				return;
			}

			state.item.images = [ 
				...state.item.images,
				...files.map(file => URL.createObjectURL(file)),
			];
		},
		[types.DELETE_IMAGE](state, file) {
			if(!state.item.images) {
				return;
			}

			const images = [...state.item.images];
			const idx = images.indexOf(file);
			images.splice(idx, 1);
			state.item.images = images;
		},
		[types.DELETE_JOB](state, idToDelete) {
			const list = [...state.list];
			const idx = list.findIndex(({ id }) => id === String(idToDelete));
			if(idx !== -1) {
				list.splice(idx, 1);
				state.list = list;
			}
		},
		[types.PROVIDER_LIST_SUCCESS]: setState('provider'),
		[types.CUSTOMER_LIST_SUCCESS]: setState('customers'),
		[types.STOREGROUP_LIST_SUCCESS]: setState('storegroups'),
		[types.MATERIALS_LIST_SUCCESS]: setState('materials'),
		[types.STORE_LIST_SUCCESS]: setState('stores'),
	}
};
