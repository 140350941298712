export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const LIST_FAILURE = 'LIST_FAILURE';
export const JOBS_LIST_SUCCESS = 'JOBS_LIST_SUCCESS';
export const JOBS_LIST_RESET = 'JOBS_LIST_RESET';
export const JOB_ITEM_SUCCESS = 'JOB_ITEM_SUCCESS';
export const JOBS_LIST_SUCCESS_LENGTH = 'JOBS_LIST_SUCCESS_LENGTH';
export const JOBS_EDIT_SUCCESS = 'JOBS_EDIT_SUCCESS';
export const PROVIDER_LIST_SUCCESS = 'PROVIDER_LIST_SUCCESS';
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';
export const STOREGROUP_LIST_SUCCESS = 'STOREGROUP_LIST_SUCCESS';
export const MATERIALS_LIST_SUCCESS = 'MATERIALS_LIST_SUCCESS';
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const ADD_IMAGES = 'ADD_IMAGES';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_JOB = 'DELETE_JOB';
