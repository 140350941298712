<template>
	<ion-input
		@click="open"
		:value="selectValueObj.bezeichnung || 'Auswählen'"
		readonly
		:disabled="disabled"
	></ion-input>

	<ion-modal :is-open="isOpen" class="search-modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>{{ title }}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-searchbar debounce="200" @ionChange="search" @ionClear="clear" :value="searchTerm"></ion-searchbar>
		<ion-list :class="cssclass">
			<ion-radio-group @ionChange="select" :value="selectedId">
				<ion-item v-for="item in filteredItems" :key="item.id" :value="item.id">
					<ion-radio :value="item.id"></ion-radio>
					<ion-label>{{ item.bezeichnung }}</ion-label>
				</ion-item>
			</ion-radio-group>
		</ion-list>

		<ion-footer>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="close">Abbrechen</ion-button>
				</ion-buttons>
				<ion-buttons slot="end">
					<ion-button color="primary" @click="choose">Auswählen</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-footer>
	</ion-modal>
</template>


<script>
import {
	IonList,
	IonItem,
	IonTitle,
	IonRadio,
	IonRadioGroup,
	IonSearchbar,
	IonHeader,
	IonInput,
	IonFooter,
	IonLabel,
	IonModal,
	IonToolbar,
	IonButtons,
	IonButton
} from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
	name: 'SearchModal',
	props: {
		title: { type: String, default: 'Super Modal' },
		cssclass: { type: String },
		items: {
			type: Array,
		},
		disabled: {
			type: Boolean,
		}
	},
	emits: ['onInput'],
	components: {
		IonTitle,
		IonRadio,
		IonRadioGroup,
		IonSearchbar,
		IonHeader,
		IonInput,
		IonFooter,
		IonList,
		IonItem,
		IonLabel,
		IonModal,
		IonToolbar,
		IonButtons,
		IonButton
	},
	setup(props, { emit }) {
		const isOpen = ref(false);
		const searchTerm = ref('');
		const selectedId = ref();
		const selectValueObj = ref({});

		const filteredItems = computed(() => {
			const term = searchTerm.value;
			return props.items.filter(item => item.bezeichnung.toLowerCase().indexOf(term.toLowerCase()) !== -1);
		});

		return {
			isOpen,
			searchTerm,
			filteredItems,
			selectedId,
			selectValueObj,
			open() {
				isOpen.value = true;
			},
			close() {
				searchTerm.value = '';
				selectedId.value = null;
				selectValueObj.value = {};
				isOpen.value = false;
			},
			choose() {
				const item = props.items.find(({ id }) => id === selectedId.value);
				selectValueObj.value = item;
				emit('onInput', {
					detail: {
						value: selectValueObj.value.id,
					}
				});
				isOpen.value = false;
			},
			clear() {
				searchTerm.value = '';
			},
			search({ detail: { value } }) {
				searchTerm.value = value;
			},
			select({ detail: { value } }) {
				selectedId.value = value;
			}
		};
	},
});
</script>

<style>
.md .search-modal ion-list {
	height: calc(100% - 56px - 56px - 56px);
	overflow: auto;
}
.ios .search-modal ion-list {
	height: calc(100% - 44px - 60px - 50px);
	overflow: auto;
}
</style>
